import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Box, AppBar, Toolbar, Grid, Typography, Container, IconButton, MenuItem, Slide } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import SocialMediaGrid from "./SocialMediaGrid";
import ScrollToTop from "./ScrollToTop";

const Header = (props) => {
    const sxLogo = { display: "flex", alignItems: "center" };
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const sliderRef = useRef(null);

    const handleMenuClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleMenuClose = () => {
        setIsMenuOpen(false);
    };

    const getNavigationItems = () => {
        let navigation = [];

        props.navigationItems.forEach((navigationItem) => {
            if (navigationItem.header) {
                navigation.push(
                    <Typography
                        variant="h6"
                        component={Link}
                        to={navigationItem.path}
                        sx={props.sx.link ? { margin: "0", ...props.sx.link } : props.sx.link}
                        key={navigationItem.label}
                    >
                        {navigationItem.label}
                    </Typography>
                );
            }
        });

        return navigation;
    };

    useEffect(() => {
        const handleClickOutsideSlider = (event) => {
            if (sliderRef.current && !sliderRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutsideSlider);

        return () => {
            document.removeEventListener("mousedown", handleClickOutsideSlider);
        };
    }, []);

    const handleTouchStart = (event) => {
        const touchStartX = event.touches[0].clientX;
        sliderRef.current.startX = touchStartX; // Store the initial touch position
    };

    const handleTouchMove = (event) => {
        const touchMoveX = event.touches[0].clientX;
        const swipeDistance = touchMoveX - sliderRef.current.startX;
        const minSwipeDistance = 100;

        if (swipeDistance > minSwipeDistance) {
            setIsMenuOpen(false);
        }
    };

    return (
        <Box component="header">
            <ScrollToTop />
            <AppBar component="nav" elevation={1} position="static">
                <Container maxWidth="md" sx={props.sx.container ? props.sx.container : {}}>
                    <Toolbar disableGutters sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <Box sx={props.sx.logo ? { ...props.sx.logo, ...sxLogo } : sxLogo}>
                            <img src={props.logo} alt="Hipp-Hopp Transfer" style={{ width: "100%", height: "100%" }} />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                            <Grid container justifyContent="center">
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container justifyContent="center" alignItems="center">
                                        <SocialMediaGrid
                                            urls={props.socialMediaLinks}
                                            sx={{ icons: { color: props.socialMediaIconColor } }}
                                            iconSize={props.socialMediaIconSize}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    sx={{ flexWrap: "wrap", margin: "8px 0 8px 0" }}
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
                                    >
                                        {getNavigationItems()}
                                    </Grid>

                                    <Grid container justifyContent="center" alignItems="center">
                                        <IconButton
                                            edge="end"
                                            aria-label="menu"
                                            onClick={handleMenuClick}
                                            sx={{ display: { xs: "block", sm: "block", md: "none" }, margin: "0" }}
                                        >
                                            <FontAwesomeIcon icon={faBars} color={props.mobileMenuIconColor} />
                                        </IconButton>
                                    </Grid>
                                    <Slide
                                        direction="left"
                                        in={isMenuOpen}
                                        mountOnEnter
                                        unmountOnExit
                                        sx={{
                                            position: "fixed",
                                            top: 0,
                                            right: 0,
                                            bottom: 0,
                                            width: "50vw",
                                            height: "100vh",
                                            bgcolor: "white",
                                            padding: "16px 0 16px 0",
                                            zIndex: 9999,
                                            overflowY: "auto",
                                        }}
                                    >
                                        <Box
                                            ref={sliderRef}
                                            onTouchStart={handleTouchStart}
                                            onTouchMove={handleTouchMove}
                                            onTouchEnd={handleMenuClose}
                                        >
                                            {props.navigationItems.map((navigationItem) => {
                                                if (navigationItem.header) {
                                                    return (
                                                        <MenuItem
                                                            key={navigationItem.label}
                                                            component={Link}
                                                            to={navigationItem.path}
                                                            onClick={handleMenuClose}
                                                        >
                                                            <Typography color="primary">
                                                                {navigationItem.label}
                                                            </Typography>
                                                        </MenuItem>
                                                    );
                                                } else return null;
                                            })}
                                        </Box>
                                    </Slide>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={props.sx.logo ? { ...props.sx.logo, ...sxLogo } : sxLogo}>
                            {/* <img
                                src={props.secondaryLogo}
                                alt="Hipp-Hopp Transfer"
                                style={{ width: "100%", height: "100%" }}
                            /> */}
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </Box>
    );
};

Header.defaultProps = {
    sx: { link: { mr: 2 } },
    iconSize: "medium",
    socialMediaIconColour: "black",
    mobileMenuIconColor: "black",
};

export default Header;
