import React, { lazy, Suspense } from "react";

// Pages
const HomePage = lazy(() => import("../pages/HomePage"));
const PessengerTransportPage = lazy(() => import("../pages/PessengerTransportPage"));
const ContactUsPage = lazy(() => import("../pages/ContactUsPage"));
const PricesPage = lazy(() => import("../pages/PricesPage"));
const CN22FormPage = lazy(() => import("../pages/CN22FormPage"));

const AboutPage = lazy(() => import("../pages/AboutPage"));
const TermsPage = lazy(() => import("../pages/TermsPage"));

const navigationItems = [
    {
        label: "Home",
        path: "/",
        element: HomePage,
        header: false,
        footer: false,
    },
    {
        label: "Csomagszállítás",
        path: "/#rendeles",
        element: HomePage,
        header: true,
        footer: true,
    },

    {
        label: "Személyszállítás",
        path: "/szemelyszallitas",
        element: PessengerTransportPage,
        header: true,
        footer: true,
    },

    {
        label: "Árak",
        path: "/arak",
        element: PricesPage,
        header: true,
        footer: true,
        subItems: [],
    },
    {
        label: "CN 22",
        path: "/cn-22",
        element: CN22FormPage,
        header: true,
        footer: true,
        subItems: [],
    },
    {
        label: "Rólunk",
        path: "/rolunk",
        element: AboutPage,
        header: false,
        footer: true,
        subItems: [],
    },
    {
        label: "Kapcsolat",
        path: "/kapcsolat",
        element: ContactUsPage,
        header: true,
        footer: true,
        subItems: [],
    },
    {
        label: "Szállítási Feltételek",
        path: "/szallitasi-feltetelek",
        element: TermsPage,
        header: false,
        footer: true,
    },
];

export default navigationItems;
