import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Typography, Container, Hidden } from "@mui/material";

import "./App.css";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import theme from "./configs/theme";

import Header from "./components/navigation/Header";
import Footer from "./components/navigation/Footer";
import Spinner from "./components/Spinner";

import SiteLogo from "./assets/logo.png";

import dates from "./data/dates.json";
import Flag from "react-world-flags";

import navigationItems from "./configs/navigationItems";

const socialMediaLinks = {
    // facebook: "https://www.facebook.com/Ozone-Solution-102102291432637",
    // instagram: "https://www.instagram.com/viglaszlo69/",
    // youtube: "https://www.youtube.com/watch?v=4zGSVEAVNEg&t=11s",
    whatsapp: "https://wa.me/07852681625",
    // google: "https://www.google.com/search?q=Ozone+Solution+Hove",
    // bark: "https://www.bark.com/en/gb/company/ozone-solution/yyzG3/",
};

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Header
                navigationItems={navigationItems}
                logo={SiteLogo}
                // secondaryLogo={SecondarySiteLogo}
                socialMediaIconColor="white"
                mobileMenuIconColor="white"
                socialMediaIconSize="md"
                // socialMediaLinks={socialMediaLinks}
                sx={{
                    container: {
                        marginTop: "20px",
                        marginBottom: "20px",
                    },
                    logo: {
                        width: "250px",
                    },
                    link: { mr: 2, color: "#01223b", textDecoration: "none" },
                }}
            />

            <Routes>
                {navigationItems.map((navigationItem, index) => {
                    if (navigationItem.path && navigationItem.element) {
                        const Page = navigationItem.element;

                        return (
                            <Route
                                exact={index === 0}
                                path={navigationItem.path}
                                key={navigationItem.path}
                                element={
                                    <Suspense fallback={<Spinner />}>
                                        <Page />
                                    </Suspense>
                                }
                            />
                        );
                    }

                    return null;
                })}

                {navigationItems.map((navigationItem) => {
                    if (navigationItem.subItems && navigationItem.subItems.length > 0) {
                        return navigationItem.subItems.map((subItem) => {
                            const SubPage = subItem.element;

                            return (
                                <Route
                                    path={subItem.path}
                                    key={subItem.path}
                                    element={
                                        <Suspense fallback={<Spinner />}>
                                            <SubPage />
                                        </Suspense>
                                    }
                                />
                            );
                        });
                    }

                    return null;
                })}
            </Routes>

            <Container maxWidth={false} style={{ marginTop: "80px" }}>
                <Container>
                    <Typography variant="h5" id="rolunk" align="center">
                        Következő szállítási dátumok
                    </Typography>

                    {dates.map((element, i) => {
                        return (
                            <Typography key={i} variant="body1" align="center">
                                <Flag code={element.departure === "Anglia" ? "gb" : "hun"} height="12" />{" "}
                                {element.departure} -{" "}
                                <Hidden smUp>
                                    <br />
                                </Hidden>
                                <Flag code={element.destination === "Anglia" ? "gb" : "hun"} height="12" />{" "}
                                {element.destination}
                                {": "}
                                <Hidden smUp>
                                    <br />
                                </Hidden>{" "}
                                {element.available ? "Csomaggyűjtés" : ""} {element.dates.collection}{" "}
                                {!element.available ? "megtelt" : ""}
                                {element.departure === "Anglia" && dates.length !== i + 1 && (
                                    <hr style={{ marginTop: "15px", width: "50%" }} />
                                )}
                            </Typography>
                        );
                    })}
                </Container>
            </Container>

            <Footer
                navigationItems={navigationItems}
                logo={SiteLogo}
                socialMediaLinks={socialMediaLinks}
                socialMediaIconColor="#01223b"
                socialMediaIconSize="md"
                phoneNumberColor="#01223b"
                phoneNumbers={["07852681625", "06303743485"]}
                copyright={`${new Date().getFullYear()} Hipp-Hopp Transfer`}
                sx={{
                    backLinkContainer: {
                        color: "#01223b",
                    },
                    backLink: {
                        color: "#01223b",
                        textDecoration: "none",
                    },
                    logo: {
                        width: "250px",
                    },
                    link: { mr: 2, color: "#01223b", textDecoration: "none" },
                }}
            />
        </ThemeProvider>
    );
}

export default App;
