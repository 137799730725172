import { createTheme } from "@mui/material/styles";

const primary = "#00A3D5BF";

const theme = createTheme({
    palette: {
        primary: {
            main: primary,
        },
        secondary: {
            main: "#f50057",
        },
    },
    typography: {
        h1: { fontSize: "40px", textAlign: "center", color: primary, marginTop: "16px", marginBottom: "24px" },
        h2: { fontSize: "36px", textAlign: "center", color: primary, marginTop: "16px", marginBottom: "24px" },
        h3: { fontSize: "32px", textAlign: "center", color: primary, marginTop: "16px", marginBottom: "24px" },
        h4: { fontSize: "28px", textAlign: "center", color: primary, marginTop: "16px", marginBottom: "24px" },
        h5: { fontSize: "24px", textAlign: "center", color: primary, marginTop: "16px", marginBottom: "24px" },
        h6: { fontSize: "20px", textAlign: "center", color: primary, marginTop: "16px", marginBottom: "24px" },
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    marginTop: "32px",
                    marginBottom: "32px",
                    textAlign: "justify",
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                body1: {
                    marginTop: "24px",
                    marginBottom: "24px",
                },
                // h6: {
                //     marginTop: "16px",
                //     marginBottom: "16px",
                // },
            },
        },
        // MuiCardContent: { styleOverrides: { root: { marginTop: "0px" } } },
    },
});

export default theme;
